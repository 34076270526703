<template>
  <div class="home">
    <!--    <header>-->
    <!--      <div class="back_but" @click="goToBack"><img :src="require('@/assets/back.png')"></div>-->
    <!--      <div>兑换周边</div>-->
    <!--    </header>-->
    <!--    {{giftData}}-->
<!--        {{ address }}-->
<!--    {{giftData}}-->
    <main>
      <div class="scroll">
        <img :src="require('@/assets/bg.png')" class="bg">
        <div class="container">
          <div class="commodity_box">
            <div class="row" v-for="(v,k) in giftDataHandle" :key="k">
              <div class="item" v-for="(val,key) in v" :key="key">
                <div>
                  <VanImage :src="val.product_icon">
                    <template v-slot:loading>
                      <VanImage :src="require('@/assets/loading.png')" class="loading_img"/>
                    </template>
                  </VanImage>
                </div>
                <div>
                  <p>{{ val.commodity_desc }}</p>
                  <p>{{ val.shuquan_price }}金币</p>
                </div>
              </div>
            </div>
            <!--            <div class="row">-->
            <!--              <div class="item" @click="test">-->
            <!--                <div>-->
            <!--                  <VanImage src="http:\/\/image2.itrm8z3b.com\/product_cover\/202108\/5424dc467c6e058df230844934a381b1.jpg">-->
            <!--                    <template v-slot:loading>-->
            <!--                      <VanImage :src="require('@/assets/loading.png')" class="loading_img"/>-->
            <!--                    </template>-->
            <!--                  </VanImage>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <p>1000000金币</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="item">-->
            <!--                <div>-->
            <!--                  <VanImage src="https://pic3.zhimg.com/v2-9dfb9bd28656a13d7d57793c853dfb52_r.jpg?source=172ae18b">-->
            <!--                    <template v-slot:loading>-->
            <!--                      <VanImage :src="require('@/assets/loading.png')" class="loading_img"/>-->
            <!--                    </template>-->
            <!--                  </VanImage>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <p>1000000金币</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="row">-->
            <!--              <div class="item" @click="test">-->
            <!--                <div>-->
            <!--                  <VanImage src="http:\/\/image2.itrm8z3b.com\/product_cover\/202108\/5424dc467c6e058df230844934a381b1.jpg">-->
            <!--                    <template v-slot:loading>-->
            <!--                      <VanImage :src="require('@/assets/loading.png')" class="loading_img"/>-->
            <!--                    </template>-->
            <!--                  </VanImage>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <p>1000000金币</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="item">-->
            <!--                <div>-->
            <!--                  <VanImage src="https://pic3.zhimg.com/v2-9dfb9bd28656a13d7d57793c853dfb52_r.jpg?source=172ae18b">-->
            <!--                    <template v-slot:loading>-->
            <!--                      <VanImage :src="require('@/assets/loading.png')" class="loading_img"/>-->
            <!--                    </template>-->
            <!--                  </VanImage>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <p>1000000金币</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="rule">
            <div class="rule_title"><img :src="require('@/assets/rule_title.png')" alt=""></div>
            <div v-for="(v,k) in roue_content" :key="k">
              {{ v }}<br/>
            </div>
          </div>
          <div class="select_box">
            <div class="item"><span>兑换</span>
              <div @click="showPicker = true"><span>{{ pickerValue }}</span><img :src="require('@/assets/back.png')">
              </div>
            </div>
            <button @click="submit">提交</button>
          </div>
        </div>
      </div>
    </main>
    <VanPopup v-model="showPicker" round position="bottom">
      <VanPicker
          show-toolbar
          :default-index="pickerIndex"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </VanPopup>
    <VanPopup v-model="showShipAddress" class="ship_address_box">
      <h6>确认收货地址</h6>
      <p class="add">您的地址：</p>
      <p class="add_c">
        {{ address.user_address.province }}{{ address.user_address.city }}{{
          address.user_address.town
        }}{{ address.user_address.address_details }}</p>
      <div class="buts">
        <button @click="showShipAddress = false">取消</button>
        <button @click="determineSubmit">确认</button>
      </div>
      <div class="revise" @click="toAddress"><i>修改地址</i></div>
    </VanPopup>
  </div>
</template>

<script>
import {Image as VanImage, Picker as VanPicker, Popup as VanPopup} from 'vant';

export default {
  name: 'Home',
  data() {
    return {
      showPicker: false,
      showShipAddress: false,
      pickerIndex: 0,
      pickerValue: '',
      columns: [],
      giftData: [],
      roue_content: [],
      address: {user_address: {}},
      mobileIos: false,
    };
  },
  components: {
    VanImage,
    VanPicker,
    VanPopup
  },
  created() {
    window.setGiftData = this.setGiftData
    window.setAddressData = this.setAddressData
    if (window.webkit && window.webkit.messageHandlers.getGiftData.postMessage){
      this.mobileIos = true
    }

    if (this.mobileIos){
      window.webkit.messageHandlers.getGiftData.postMessage("")
    } else {
      this.setGiftData(android.getGiftData())
    }


  },
  computed: {
    giftDataHandle() {
      let data = [[]]
      for (const giftDatum of this.giftData) {
        if (data[data.length - 1].length < 2) {
          data[data.length - 1].push(giftDatum)
        } else {
          data.push([giftDatum])
        }
      }
      return data
    },
  },
  methods: {
    setGiftData(data){
      try {
        let giftData = (typeof data == "string") ? JSON.parse(data) : data
        this.roue_content = giftData.roue_content.split("\r\n")
        this.roue_content.splice(0, 1)
        this.giftData = giftData.list || []
        for (const giftDatum of this.giftData) {
          this.columns.push(giftDatum.product_name)
        }
      } catch (err) {
        alert(err)
      }
      this.pickerValue = this.columns[this.pickerIndex]
    },
    setAddressData(add){
      if (this.giftData[this.pickerIndex].product_type == 1) {
        if (!add) {
          this.toAddress()
          return
        }
        this.address = (typeof add == "string") ? JSON.parse(add) : add
        if (Object.keys(this.address.user_address).length < 1){
          this.toAddress()
          return
        }
        this.showShipAddress = true

      } else if (this.giftData[this.pickerIndex].product_type == 0) {
        this.determineSubmit()
      }

    },
    onConfirm(value, index) {
      this.pickerValue = value
      this.pickerIndex = index
      this.showPicker = false
    },
    toAddress() {
      this.mobileIos ? window.webkit.messageHandlers.toAddress.postMessage("") : android.toAddress()
      this.showShipAddress = false
    },
    submit() {
      if (this.mobileIos){
        window.webkit.messageHandlers.getAddressData.postMessage("")
      } else {
        this.setAddressData(android.getAddressData())
      }

    },
    determineSubmit() {
      let obj = JSON.stringify({
        receiver_name: this.address.user_address.receiver_name,
        receiver_mobile: String(this.address.user_address.receiver_mobile),
        exchange_id: String(this.giftData[this.pickerIndex].id),
        product_id: String(this.giftData[this.pickerIndex].product_id),
        address_details: this.address.user_address.province + this.address.user_address.city + this.address.user_address.town + this.address.user_address.address_details,
      })
      this.mobileIos ? window.webkit.messageHandlers.acceptGift.postMessage(obj) : android.acceptGift(obj)
      this.showShipAddress = false
    },
  }
}
</script>
<style lang="scss">
.loading_img {
  > img {
    height: 100%;
    width: auto;
    margin: auto;
  }
}
</style>
<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  height: 100%;

  //header {
  //  width: 100%;
  //  height: 44px;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //
  //  font-size: 16px;
  //  color: #323334;
  //  letter-spacing: 0;
  //  text-align: center;
  //  font-weight: 500;
  //  position: relative;
  //
  //  .back_but {
  //    position: absolute;
  //    left: 0;
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    height: 44px;
  //    width: 44px;
  //
  //    img {
  //      height: 18px;
  //      position: relative;
  //    }
  //  }
  //}

  main {
    overflow: hidden;
    overflow-y: scroll;
    flex: 1;
    position: relative;

    .scroll {
      background: rgb(237, 128, 103);
      padding-bottom: 14px;

    }

    .bg {
      width: 100%;
    }

    .container {
      padding: 0 15px;
      margin-top: -80px;
      position: relative;

      .commodity_box {
        //width: 345px;
        //height: 213px;

        padding: 12px 13px;
        box-sizing: border-box;
        background-image: linear-gradient(180deg, #FFEDDF 0%, #FFFFFF 25%);
        border-radius: 6px;


        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .row:not(:first-child) {
          padding-top: 12px;
          box-sizing: border-box;
        }

        .item {
          width: 157px;
          box-shadow: 0px 1px 3px #ffdddd;

          > div {
            overflow: hidden;
          }

          > div:nth-child(1) {
            height: 157px;
            background: #F6F5F9;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          > div:nth-child(2) {
            letter-spacing: 0;
            background: #FFFFFF;
            box-sizing: border-box;
            p{
              padding: 0 10px;
              box-sizing: border-box;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: normal;
              line-height: 26px;
            }
            >p:first-child{
              font-size: 11px;
              color: #6d6d6d;
            }
            >p:last-child{
              font-size: 12px;
              font-weight: 500;
              color: #FC641D;

            }
          }

          .loading_img {
            height: 9.8125rem;
            width: 9.8125rem;

            > img {
              height: 100%;
              width: auto;
            }
          }
        }
      }

      .rule {
        box-sizing: border-box;
        margin-top: 10px;
        width: 345px;
        background-image: linear-gradient(180deg, #FFEDDF 0%, #FFFFFF 33%);
        border-radius: 6px;
        padding: 0 13px 13px;
        font-size: 12px;
        color: #323334;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400;

        .rule_title {
          height: 20px;
          padding-top: 17px;
          padding-bottom: 15px;

          img {
            margin: auto;
            height: 100%;
          }
        }
      }
    }
  }
}

.select_box {
  width: 345px;
  height: 153px;
  background: #FFFFFF;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 19px;
  box-sizing: border-box;

  .item {
    display: flex;
    align-items: center;

    > span {
      margin-right: 20px;
      font-size: 14px;
      color: #323334;
      line-height: 22px;
      font-weight: 600;
    }

    > div {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 271px;
      height: 40px;
      background: #F6F5F9;
      border-radius: 20px;
      font-size: 14px;
      color: #323334;
      line-height: 22px;
      font-weight: 400;
      padding: 0 20px;

      > img {
        height: 11.8px;
        transform: rotateZ(-90deg);
      }
    }
  }

  button {
    margin-top: 34px;
    width: 319px;
    height: 44px;
    background: #FF8350;
    border: 0;
    border-radius: 22px;

    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.27px;
    text-align: center;
    font-weight: 400;
  }
}

.ship_address_box {
  width: 270px;
  background: #FFFFFF;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;

  > h6 {
    font-size: 14px;
    color: #35363B;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
  }

  .add {
    font-size: 12px;
    color: #323334;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 400;
    margin-top: 12px;
  }

  .add_c {
    font-size: 14px;
    color: #323334;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 500;
    margin-top: 8px;
    word-break: break-all;
  }

  .buts {
    margin-top: 36px;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;

    button {
      border: none;
    }

    button:nth-child(1) {
      width: 86px;
      height: 40px;
      background: #F1F1F1;
      border-radius: 205px;
      font-size: 14px;
      color: #FF8350;
      letter-spacing: 0;
      text-align: center;
      line-height: 17px;
      font-weight: 400;
    }

    button:nth-child(2) {
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 17px;
      font-weight: 400;
      width: 132px;
      height: 40px;
      background: #FF8350;
      border-radius: 205px;
    }
  }

  .revise {
    text-align: center;

    > i {
      font-size: 12px;
      color: #196CF2;
      letter-spacing: 0;
      text-align: center;
      line-height: 17px;
      font-weight: 400;
      font-style: normal;
      border-bottom: 1px solid #196CF2;
    }
  }

}
</style>
